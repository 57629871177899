<template>
    <SamplePackSelectorBlock v-editable="blok" :data="samplePackSelector" />
</template>

<script setup lang="ts">
import SamplePackSelectorType from '~/types/SamplePackSelectorType';
import SamplePackType from '~/types/SamplePackType';
import SamplePackSelectorBlock from '~/components/page-building/sample-pack-selector/SamplePackSelectorBlock.vue';
import ImageType from '~/types/ImageType';
import { ProductVariation } from '~/types/product';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const samplePackSelector = computed<SamplePackSelectorType>(() => {
    const samplePacks = props.blok.sample_packs
        ? props.blok.sample_packs.map((pack: any) => {
              return {
                  title: pack.title,
                  subtitle: pack.subtitle,
                  description: richTextToHtml(pack.description) as string,
                  product: {
                      id: pack.product.content.database_product.id,
                      sku: pack.product.content.database_product.sku,
                      image:
                          pack.product.content.image &&
                          pack.product.content.image.filename
                              ? ({
                                    title: pack.product.content.image.title,
                                    alt: pack.product.content.image.alt,
                                    src: pack.product.content.image.filename,
                                } as ImageType)
                              : null,
                      name: pack.product.content.name,
                      description: richTextToHtml(
                          pack.product.content.description,
                      ) as string,
                      vEditable: pack.product,
                  } as ProductVariation,
                  vEditable: pack,
              } as SamplePackType;
          })
        : [];

    return {
        heading: props.blok.heading,
        samplePacks,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    } as SamplePackSelectorType;
});
</script>
